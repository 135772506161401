<template>
  <basic-layout>
    <h2 class="text-center mt-5 mb-5">
      {{ $t("notFound.explanation") }}
    </h2>
    <b-button to="/" class="nf-btn mb-5"> {{ $t("notFound.button") }}</b-button>
    <img :src="notFoundImg" class="not-found-img" />
  </basic-layout>
</template>

<script>
import BasicLayout from "../layouts/BasicLayout.vue";
import notFoundImg from "../assets/img/not_found.png";
export default {
  components: { BasicLayout },
  data() {
    return {
      notFoundImg,
    };
  },
};
</script>

<style lang="scss" scoped>
.not-found-img {
  display: block;
  margin: 0 auto;
  height: 35vh;
}

.nf-btn {
  margin: 0 auto;
  display: block;
  max-width: 200px;
}
</style>